//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import { layoutRoutesUser } from "@/router";
import xSocketLink from "@/assets/xSocketLink";
import userViewMenu from "@/components/userViewMenu";
import { setAllTeams } from "@/store/mutations";
export default {
  components: {
    Manual: () => import("./manual"),
    userViewMenu,
  },
  data() {
    "#main";
    return {
      roleType: 1, //角色
      menuIndex: this.$menuIndex,
      showBtn: true,
      layoutRoutesUser,
      applyCount: null, //申请数量
      informationCount: null, //消息数量
      drawerManual: false, //使用手册抽屉
    };
  },
  computed: {
    ...mapState(["mobile", "user"]),
    isSell() {
      return process.env.VUE_APP_SELL;
    },
    appName() {
      return process.env.VUE_APP_NAME;
    },
  },
  watch: {
    roleType(val, oval) {
      if (val != oval) {
        this.$refs.manual.initSelStep();
      }
    },
  },
  methods: {
    ...mapActions(["mobile_ToggleState", "user_setUser", "setAllTeams"]),
    async queryAllTeams() {
      const { res, data } = await this.$http.get("/Teams/GetAllTeams.ashx", {
        params: {
          searchText: null,
          type: 2,
        },
      });
      if (res !== 0) return;
      this.setAllTeams(data);
    },
    /**
     * 获取用户信息
     */
    getUserInfo() {
      this.$http
        .get("/Management/UserManagement/GetUserDetail.ashx", {
          params: { usId: null },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.user_setUser(resp.data);
            if (resp.data.TeamCount == 0) {
              this.$router.push("/teamManagement");
            }
          }
        });
    },
    /**
     * 下载客户端
     */
    handleDowload() {
      window.location.href = `${process.env.VUE_APP_CDNURL}/download.ashx`;
    },
    hideMobileMenu(notPro = true) {
      if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
        this.menuIndex = null;
      }
      this.showBtn = true;
      if (notPro) {
        this.eid = null;
      }
      this.mobile_ToggleState({
        action: "all",
        value: false,
      });
    },
    /**
     * 操作手册
     */
    handleManual() {
      console.log(123);
      this.drawerManual = true;
    },
    /**
     * 官网
     */
    handleHome() {
      window.open(process.env.VUE_APP_DIC);
    },
    /**
     * 退出
     */
    exit() {
      this.$xStorage.removeItem("token");
      this.$xStorage.removeItem("user-role");
      // this.$xSocket.destroy();
      // this.$xSocket = 0;
      this.$router.push("/");
      this.$message("退出成功");
    },
    /**
     * 获取申请条数
     */
    getCount() {
      Promise.all([
        this.$http.post("/Teams/InvitedOrApply/GetApplyMessage.ashx"),
        this.$http.post("/Information/GetSumNewInformation.ashx"),
      ]).then((resp) => {
        if (resp[0].res == 0) {
          this.applyCount = resp[0].data;
        }
        if (resp[1].res == 0) {
          this.informationCount = resp[1].data.Count;
        }
      });
    },
    closeMobileMenu() {
      this.mobile_ToggleState({
        action: "menu",
        value: false,
      });
    },
    drawerOnSelect() {
      this.closeMobileMenu();
    },
  },
  mounted() {
    this.getCount();
    this.getUserInfo();
    this.queryAllTeams();
    //建立Socket链接
    // return;
    this.$http.get("/User/GetLocalIP.ashx").then((resp) => {
      if (resp.res == 0) {
        this.$xStorage.setItem("user-local-iP", resp.data);
        let _this = this;
        new xSocketLink({
          onMsg: (res) => {
            if (["25"].includes(res.res)) {
              _this.$E.$emit("loadpic", res); //发来开始进程截图
            } else if (["26"].includes(res.res)) {
              _this.$E.$emit("loadingpic", res); //发来加载进程截图
            } else if (["120"].includes(res.res)) {
              this.applyCount = res.data.ApplySumCount; //更新团队申请数量
            } else if (["122"].includes(res.res)) {
              this.informationCount = res.data.InformationCount; //更新消息数量
            } else if (["28"].includes(res.res)) {
              _this.$E.$emit("loadcamerapic", res); //发来通知成员拍照
            } else if (["29"].includes(res.res)) {
              _this.$E.$emit("loadingcamerapic", res); //发来返回摄像头拍照图片
            }
          },
        });
        console.log(this.localIP);
      }
    });
  },
};
